// log
import store from "../store";

const fetchDataRequest = () => {
  return {
    type: "CHECK_DATA_REQUEST",
  };
};

const fetchDataSuccess = (payload) => {
  return {
    type: "CHECK_DATA_SUCCESS",
    payload: payload,
  };
};

const fetchDataFailed = (payload) => {
  return {
    type: "CHECK_DATA_FAILED",
    payload: payload,
  };
};

export const fetchData = () => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      let minStake = await store
        .getState()
        .blockchain.smartContract.methods.minStake()
        .call();

        let rewardsPerHour = await store
        .getState()
        .blockchain.smartContract.methods.rewardsPerHour()
        .call();

        let timelock = await store
        .getState()
        .blockchain.smartContract.methods.timelock()
        .call();

      dispatch(
        fetchDataSuccess({
          minStake,
          rewardsPerHour,
          timelock,
        })
      );
    } catch (err) {
      console.log(err);
      dispatch(fetchDataFailed("Could not load data from contract."));
    }
  };
};
