import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Web3 from "web3";
import "rsuite/dist/rsuite.min.css";
import { InputNumber } from 'rsuite';
import Footer from "./footer";
import Fade from 'react-reveal/Fade';

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  border-radius: 50px;
  border: none;
  background-color: var(--secondary);
  padding: 10px;
  font-weight: bold;
  color: black;
  width: 180px;
  cursor: pointer;
  box-shadow: 0px 4px 0px -2px rgba(10, 10, 10, 0.4);
  -webkit-box-shadow: 0px 5px 0px -2px rgba(10, 10, 10, 0.5);
  -moz-box-shadow: 0px 5px 0px -2px rgba(10, 10, 10, 0.5);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 100%;
  border: none;
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: white;
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 15px 0px -2px rgba(200, 200, 200, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(200, 200, 200, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(200, 200, 200, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 50%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const StyledLogo = styled.img`
  width: 200px;
  @media (min-width: 767px) {
    width: 300px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  box-shadow: 0px 5px 11px 2px rgba(0, 0, 0, 0.7);
  border: 4px solid black;
  background-color: black;
  border-radius: 100%;
  width: 200px;
  @media (min-width: 900px) {
    width: 250px;
  }
  @media (min-width: 1000px) {
    width: 300px;
  }
  transition: width 0.5s;
`;

export const StyledLink = styled.a`
  color: #CECECE;
  text-decoration: none;
`;

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Stake your PLUR`);
  const [Amount, setAmount] = useState(10);
  const [Rewards, setRewards] = useState(0);
  const [TokenStaked, setTokenStaked] = useState(0);
  const AmountInputRef = useRef();
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });


  const STAKETOKEN = () => {
    let cost = Amount;
    let TotalAmount = Web3.utils.toWei(cost.toString(), "ether");
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalGasLimit = String(gasLimit);
    console.log("Amount: ", TotalAmount);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Staking your ${CONFIG.SYMBOL}...`);
    setClaimingNft(true);
        blockchain.smartToken.methods
        .approve(CONFIG.CONTRACT_ADDRESS, TotalAmount)
        .send({
          to: CONFIG.TOKEN,
          from: blockchain.account,
        })
        .then((receipt) => {
          console.log(receipt);
          setFeedback(
            `Approved for Staking`
            );
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
          });
        blockchain.smartContract.methods
          .deposit(TotalAmount)
          .send({
            gasLimit: String(totalGasLimit),
            to: CONFIG.CONTRACT_ADDRESS,
            from: blockchain.account,
          })
          .once("error", (err) => {
            console.log(err);
            setFeedback("Sorry, something went wrong please try again later.");
            setClaimingNft(false);
          })
          .then((receipt) => {
            console.log(receipt);
            setFeedback(
              `Stake Successfull.`
            );
            setClaimingNft(false);
            dispatch(fetchData(blockchain.account));
          });
    };

    const WITHDRAWALL = () => {
      let gasLimit = CONFIG.GAS_LIMIT;
      let totalGasLimit = String(gasLimit);
      console.log("Gas limit: ", totalGasLimit);
      setFeedback(`Whitdraw your Tokens + Rewards...`);
      setClaimingNft(true);
          blockchain.smartContract.methods
            .withdrawAll()
            .send({
              gasLimit: String(totalGasLimit),
              to: CONFIG.CONTRACT_ADDRESS,
              from: blockchain.account,
            })
            .once("error", (err) => {
              console.log(err);
              setFeedback("Sorry, something went wrong please try again later.");
              setClaimingNft(false);
            })
            .then((receipt) => {
              console.log(receipt);
              setFeedback(
                `Withdraw Successfull.`
              );
              setClaimingNft(false);
              dispatch(fetchData(blockchain.account));
            });
      };

      const WITHDRAWTOKENS = () => {
        let cost = Amount;
        let TotalAmount = Web3.utils.toWei(cost.toString(), "ether");
        let gasLimit = CONFIG.GAS_LIMIT;
        let totalGasLimit = String(gasLimit);
        console.log("Gas limit: ", totalGasLimit);
        setFeedback(`Whitdraw your Tokens + Rewards...`);
        setClaimingNft(true);
            blockchain.smartContract.methods
              .withdraw(TotalAmount)
              .send({
                gasLimit: String(totalGasLimit),
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
              })
              .once("error", (err) => {
                console.log(err);
                setFeedback("Sorry, something went wrong please try again later.");
                setClaimingNft(false);
              })
              .then((receipt) => {
                console.log(receipt);
                setFeedback(
                  `Withdraw Successfull.`
                );
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
              });
        };

      const CalculateRewards = () => {
            blockchain.smartContract.methods
              .calculateRewards(blockchain.account)
              .call({
                to: CONFIG.CONTRACT_ADDRESS,
                from: blockchain.account,
              })
              .then((receipt) => {
                let TOTALREWARDS = Web3.utils.fromWei(receipt.toString(), 'ether').substring(0,6);
                setRewards(TOTALREWARDS);
                console.log("Rewards:", TOTALREWARDS);
                setClaimingNft(false);
                dispatch(fetchData(blockchain.account));
              });
        };

        const DepositTokens = () => {
          blockchain.smartContract.methods
            .getDepositInfo(blockchain.account)
            .call({
              to: CONFIG.CONTRACT_ADDRESS,
              from: blockchain.account,
            })
            .then((receipt) => {
              let Totalstaked = Web3.utils.fromWei(receipt[0].toString(), 'ether');
              setTokenStaked(Totalstaked);
              console.log("StakedTokens:", Totalstaked);
              setClaimingNft(false);
              dispatch(fetchData(blockchain.account));
            });
      };



  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
      CalculateRewards();
      DepositTokens();
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24}}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.jpeg" : null}
      >
        <s.SpacerSmall />
        <s.TextTitle
                       style={{
                
                        marginTop: "5px",
                        marginBottom: "30px",
                        objectFit: "contain",
                        fontFamily: "pump",
                        color: "white",
                        fontSize: "60px",
                        lineHeight: "1em",
                        textAlign: "center",
                        whiteSpace: "normal",
                       
                        
                        
                      }}
            >
              Stake PLUR receive PLUR.
            </s.TextTitle>
           
        <ResponsiveWrapper flex={1} style={{ padding: 24 }} test>
          
          <s.MAINContainer
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{
              backgroundColor: "var(--accent)",
              padding: 24,
              borderRadius: 24,
              boxShadow: "0px 10px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
            
            <s.BOXContainer>
            <s.TEXTBOX
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                     APY: {(data.rewardsPerHour * 8760) / 100000} %
                </s.TEXTBOX>
                <s.SpacerSmall />
                <s.TEXTBOX
                  style={{ textAlign: "center", color: "var(--accent-text)" }}
                >
                     Min Term: {(((data.timelock * 15) / 86400).toString()).substring(0,1)} DAY
                </s.TEXTBOX>
            </s.BOXContainer>
            <s.SpacerSmall />
            <s.BOXContainer>
            <s.TEXTBOX
                  style={{ textAlign: "center", color: "var(--accent-text)"}}
                >
                  TokenStaked: <br></br> {TokenStaked} PLUR
                </s.TEXTBOX>
                <s.SpacerSmall />
                <s.TEXTBOX
                  style={{ textAlign: "center", color: "var(--accent-text)"}}
                >
                     Your Rewards: <br></br> {Rewards} PLUR
                </s.TEXTBOX>
            </s.BOXContainer>

                <s.SpacerMedium />
                {blockchain.account === "" ||
                blockchain.smartContract === null ? (
                  <s.Container ai={"center"} jc={"center"}>
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      Connect to the {CONFIG.NETWORK.NAME} network
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <StyledButton
                      onClick={(e) => {
                        e.preventDefault();
                        dispatch(connect());
                        getData();
                      }}
                    >
                      CONNECT

                    </StyledButton>
                    {blockchain.errorMsg !== "" ? (
                      <>
                        <s.SpacerSmall />
                        <s.TextDescription
                          style={{
                            textAlign: "center",
                            color: "var(--accent-text)",
                          }}
                        >
                          {blockchain.errorMsg}
                        </s.TextDescription>
                      </>
                    ) : null}
                  </s.Container>
                ) : (
                  <>
                    <s.Container ai={"center"} jc={"center"} fd={"row"}>
                    <InputNumber style={{width: 300}}  postfix="PLUR" min={10} placeholder="10"
                    onChange={ e => {setAmount(e)}}
                    ref={AmountInputRef}
                    />
                    
                    </s.Container>
                    <s.SpacerSmall />
                    <s.TextDescription
                      style={{
                        textAlign: "center",
                        color: "var(--accent-text)",
                      }}
                    >
                      {feedback}
                    </s.TextDescription>
                    <s.SpacerSmall />
                    <s.MINTContainer ai={"center"} jc={"center"} fd={"row"}>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          STAKETOKEN();
                          getData();
                        }}
                      >
                        {claimingNft ? "STAKING..." : "STAKE"}
                        
                      </StyledButton>
                      <s.SpacerXSmall/>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          WITHDRAWALL();
                          getData();
                        }}
                      >
                        {claimingNft ? "Withdrawing..." : "WithdrawAll"}
                        
                      </StyledButton>
                      <s.SpacerXSmall/>
                      <StyledButton
                        disabled={claimingNft ? 1 : 0}
                        onClick={(e) => {
                          e.preventDefault();
                          WITHDRAWTOKENS();
                          getData();
                        }}
                      >
                        {claimingNft ? "Withdrawing..." : "Withdraw Tokens"}
                        
                      </StyledButton>
                    
                    </s.MINTContainer>
                    <s.SpacerSmall />
                  </>
                )}
            <s.SpacerMedium />
          </s.MAINContainer>
          <s.SpacerLarge />
        </ResponsiveWrapper>
      
        <s.SpacerMedium />
      </s.Container>
      <Footer />
    </s.Screen>
  );
}

export default App;
